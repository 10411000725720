import React from "react"
import * as styles from './Quote.module.css'

export function Quote ({ title = undefined, text }) {
  return (
    <div className={styles.component}>
      <blockquote className={styles.blockquote}>
        {title && <strong className={styles.heading}>{ title }</strong>}
        { text }
      </blockquote>
    </div>
  )
}
