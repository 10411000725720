import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Layout } from '../components/layout/Layout'
import { SiteHeader } from '../components/site-header/SiteHeader'
import { SiteFooter } from '../components/site-footer/SiteFooter'
import { HeadingMd, HeadingSm } from '../components/heading/Heading'
import { TextBanner } from '../components/text-banner/TextBanner'
import { CtaButtons } from '../components/cta-buttons/CtaButtons'
import { Quote } from '../components/quote/Quote'
import { Hero } from '../components/hero/Hero'
import { Cards } from '../components/cards/Cards'

const BehandelingenPage = () => {
  return (
    <Layout title="Behandelingen" desc="Benieuwd voor welke behandelingen u terecht kan bij de Mondhygienist? Lees het hier en maak een afspraak!">
      <SiteHeader />

      <Hero
        image={
          <StaticImage
            src="../images/diana-polekhina-iYQgnaWD8uw-unsplash.jpg"
            placeholder="blurred"
            alt=""
            loading="eager"
            formats={['auto', 'webp', 'avif']}
          />
        }
        title="Ervaart u een mondprobleem?"
        text="Blijf er niet langer mee rondlopen!"
      />

      <TextBanner>
        <HeadingMd h={2}>Behandelingen van een Mondhygienist</HeadingMd>
        <ul>
          <li>Conditie van het tandvlees controleren</li>
          <li>Poetsinstructies</li>
          <li>
            Het geven van advies en voorlichting over o.a. het ontstaan van gaatjes en tandvlees aandoeningen in relatie
            tot voedingsgewoonten
          </li>
          <li>Verwijderen van tandsteen, tandplak en aanslag</li>
          <li>Aanbrengen van fluoride</li>
          <li>Polijsten van vullingen</li>
          <li>Pocketstatus</li>
          <li>Reinigen van worteloppervlakken onder het tandvlees</li>
        </ul>

        <Quote
          title="“Wist u dat..."
          text="een mondhygiënist helpt nare adem, ontstoken tandvlees en gaatjes te voorkomen?”"
        />

        <HeadingSm h={3}>Wanneer is een bezoek aan de mondhygiënist aan te bevelen?</HeadingSm>
        <ul>
          <li>
            Als uw tandvlees bloedt, rood van kleur en/of gezwollen is. Dit duidt altijd op een tandvleesontsteking
          </li>
          <li>Uw tanden of kiezen van plaats veranderen of los gaan staan</li>
          <li>U last heeft van een vieze smaak of slechte adem</li>
          <li>Er tandsteen, tandplaque of aanslag op de tanden aanwezig is</li>
          <li>Er regelmatig cariës (gaatjes) in uw gebit ontstaan</li>
          <li>U belang hecht aan uw mondgezondheid</li>
        </ul>

        <HeadingSm h={3}>Gezond tandvlees:</HeadingSm>
        <p>
          Gezond tandvlees is roze, ligt strak om tanden en kiezen en bloedt niet als u eet of uw tanden poetst. Gezond
          tandvlees is de basis van een gezond gebit. Het tandvlees is samen met het kaakbot en de vezels het fundament
          van uw tanden en kiezen.
        </p>

        <HeadingSm h={3}>Ontstoken tandvlees (gingivitis)</HeadingSm>
        <p>
          De kleur van gezond tandvlees is roze. Ongezond of ontstoken tandvlees is vaak roder van kleur, gezwollen en
          bloedt sneller bij bijvoorbeeld tandenpoetsen. Er is een regel die altijd geldt: gezond tandvlees
          bloedt nooit! Het eerste stadium van ontstoken tandvlees wordt gingivitis genoemd, een verder gevorderd
          stadium noemen we parodontitis.
        </p>
        <HeadingSm h={3}>Ontstaan van ontstoken tandvlees</HeadingSm>
        <p>
          De ontsteking in de rand van het tandvlees wordt veroorzaakt door bacteriën in de tandplak. Tandplak is een
          zacht en kleverig, nauwelijks zichtbaar laagje van speekseleiwitten en bacteriemassa op de tanden en kiezen.
          Tandplak kan verkalken tot tandsteen dat stevig vastzit aan de tanden en kiezen. Dit proces herhaalt zich.
        </p>

        <HeadingSm h={3}>Kenmerken van ontstoken tandvlees</HeadingSm>
        <p>
          Ontstoken tandvlees is niet altijd duidelijk zichtbaar. Enkele kenmerken die kunnen optreden zijn: rood, slap
          en gezwollen tandvlees en/of bloedend tandvlees bij het poetsen. Ook een vieze smaak of een slechte
          adem kunnen duiden op ontstoken tandvlees. Ontstoken tandvlees geeft zelden pijnklachten.
        </p>

        <HeadingSm h={3}>Gevolgen van ontstoken tandvlees</HeadingSm>
        <p>
          De ontsteking in de tandvleesrand kan zich uitbreiden in de richting van het kaakbot.  Het tandvlees laat
          daardoor los van de tanden en kiezen. De ruimte die zo ontstaat, noemen we een verdiepte pocket. In de ruimte
          tussen het tandvlees en de tanden en kiezen vormt zich weer tandplak. Door deze tandplak verplaatst de
          ontsteking zich nog verder in de diepte. De vezels gaan door de ontsteking kapot en het kaakbot wordt
          afgebroken. Hierdoor worden de pockets dieper. In de verdiepte pockets verkalkt de tandplak gedeeltelijk tot
          tandsteen. Deze voortschrijdende ontsteking met afbraak van vezels en kaakbot heet parodontitis. Eenmaal
          afgebroken kaakbot kan zich niet meer herstellen.
        </p>
      </TextBanner>

      <CtaButtons />

      <Cards />

      <SiteFooter />
    </Layout>
  )
}

export default BehandelingenPage
